import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
// import SEO from "../components/seo"
import "../styles/404.scss"

// Favicons
import favicon16 from "../static/assets/favicon16.png"
import favicon32 from "../static/assets/favicon32.png"
import favicon64 from "../static/assets/favicon64.png"

const NotFoundPage = () => {
  return (
    <div className="errorPage">
      <Helmet>
         <meta charset="UTF-8"/>
         <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
         <meta http-equiv="X-UA-Compatible" content="ie=edge"/>
         <meta name="robots" content="noindex"/>
         <title>404 | Goeighta</title>
         <link rel="icon" type="image/png" sizes="16x16" href={`${favicon16}`} />
         <link rel="icon" type="image/png" sizes="32x32" href={`${favicon32}`} />
         <link rel="shortcut icon" type="image/png" href={`${favicon64}`} />
      </Helmet> 
          <div className="container">
                <div className="row">
                    <div className="errorText">
                            <h1>404!</h1>
                            <p>Parece que algo anda mal.</p>
                    </div>
                    <div className="errorSol">
                            <Link to="/">Regresar a la página principal</Link>
                    </div>
                </div>
          </div>
    </div>
  )
}


export default NotFoundPage